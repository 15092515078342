var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-dialog',{model:{value:(_vm.$store.state.modalBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "modalBusqueda", $$v)},expression:"$store.state.modalBusqueda"}},[_c('div',{staticClass:"col-12 row justify-center",staticStyle:{"width":"1200px"}},[_c('q-card',{staticClass:"q-pa-md bg-card-buscador",staticStyle:{"width":"1200px"}},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"highlight_off","flat":"","round":"","dense":""},on:{"click":function($event){return _vm.cerrar()}}})],1),_c('q-card-section',{staticClass:"scroll q-pt-none",staticStyle:{"max-height":"60vh"}},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-12 row justify-center text-center"},[_c('label',{staticStyle:{"font-size":"20px"}},[_vm._v("Buscador de convenios:")])]),_c('div',{staticClass:"col-11 row justify-center text-center"},[_c('q-input',{staticClass:"full-width row items-center q-py-md",staticStyle:{"border-radius":"10px !important"},attrs:{"type":"search","placeholder":"¿Qué convenio necesitas encontrar?","dark":"","color":"white","rounded":"","outlined":"","clearable":"","maxlength":"80"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer q-mt-md",attrs:{"name":"search","color":"white"},on:{"click":function($event){return _vm.buscar(_vm.$store.state.textBusqueda)}}})]},proxy:true}]),model:{value:(_vm.$store.state.textBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "textBusqueda", $$v)},expression:"$store.state.textBusqueda"}})],1)])]),_c('q-card-section',{staticClass:"scroll q-pt-none",staticStyle:{"max-height":"60vh"}},[_c('q-card-section',{staticClass:"q-pt-none"},[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[(
                  _vm.busquedaData &&
                  _vm.busquedaData.dataConvenios &&
                  _vm.busquedaData.dataConvenios.length == 0
                )?_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" No se han encontrado resultados ")])]):_vm._e()])]),(
              _vm.busquedaData &&
              _vm.busquedaData.dataConvenios &&
              _vm.busquedaData.dataConvenios.length > 0
            )?_c('q-card-section',[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Convenios ")])]),_vm._l((_vm.busquedaData.dataConvenios),function(item){return _c('div',{key:item.id,staticClass:"\n                  row\n                  q-ma-sm\n                  col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3\n                  bg-img\n                  cursor-pointer\n                  justify-center\n                ",staticStyle:{"opacity":"1 !important"},on:{"click":function($event){return _vm.irConvenio(item.nombre_web)}}},[_c('div',{staticClass:"col-12 q-pa-md cursor-pointer row items-center",on:{"click":function($event){return _vm.irConvenio(item.nombre_web)}}},[_c('q-img',{staticStyle:{"width":"100% !important","height":"auto !important","border-style":"!important"},attrs:{"src":item.foto}})],1),_c('div',{staticClass:"\n                    col-11\n                    row\n                    justify-center\n                    q-my-md\n                    text-center\n                    cursor-pointer\n                    items-end\n                  ",staticStyle:{"font-size":"18px"},on:{"click":function($event){return _vm.irConvenio(item.nombre_web)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.username.toUpperCase(),48, "..."))+" ")])])})],2)]):_vm._e()],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }